<template>
  <label class="app-checkbox">
    <div
      class="box"
      :class="{ '-checked': isChecked, '-disabled': disabled }"
    ></div>
    <input
      v-model="isChecked"
      class="input"
      type="checkbox"
      :disabled="disabled"
      v-bind="$attrs"
    />
    <slot />
  </label>
</template>

<script setup lang="ts">
interface Props {
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  disabled: false,
})

const isChecked = defineModel<boolean>({ required: true })
</script>

<style lang="scss" scoped>
.app-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  > .input {
    display: none;
  }

  > .box {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-right: 10px;
    background-color: $white-color;
    border: solid 1px $gray;
    border-radius: 4px;
    box-shadow: $box-shadow-2;

    &.-checked {
      background-color: $primary-color;

      &::after {
        position: absolute;
        top: 1px;
        left: 4.5px;
        width: 5px;
        height: 8.5px;
        content: '';
        border-right: solid 2px $white-color;
        border-bottom: solid 2px $white-color;
        transform: rotate(45deg);
        box-sizing: content-box;
      }
    }

    &.-disabled {
      background: $gray-9;

      &::after {
        border-color: $gray-10;
      }
    }
  }
}
</style>
